.bubble_input-container {
  height: 100vh;
  padding-top: 4rem;

  background: linear-gradient(180deg, #c4b6e0 0%, #eee1ce 100%);
  color: white;
}

.form-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
}

.error-message {
  margin-top: -3rem;
  margin-bottom: 2rem;
  padding: 0.5rem 2rem;
}

.error-message a {
  text-decoration: underline;
}

.bubble_input-description {
  height: 40%;
  width: 55%;

  background: rgba(219, 226, 137, 0.28);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  transform: matrix(1, -0.09, 0.01, 1, 0, 0);

  display: flex;
  flex-direction: column;
}

.bubble_input-description-label {
  height: 3rem;
  padding-left: 2rem;

  background: #c5a1da;
  display: flex;
  align-items: center;

  border-bottom: 1px solid black;
}

.bubble_input-description-input {
  height: 100%;

  background: #e6d9d1;
}

.bubble_input-submit {
  width: 8rem;
  height: 4rem;
  margin-right: 2rem;

  font-size: 1.25rem;
  font-weight: 500;

  align-self: flex-end;
  border-radius: 38px;
  border: none;

  position: absolute;
  bottom: 6rem;

  background: linear-gradient(180deg, #fdead1 0%, #d1c4db 100%);
  color: white;
}

.bubble_input-submit.disabled {
  background: linear-gradient(180deg, #e3d1b7 0%, #bdb0c7 100%);
  color: #6f6a6a;
}

.mushroom_net {
  position: fixed;
  bottom: 0.5rem;
}
