.landing-page-container .button {
  background: linear-gradient(90deg, #e8bec9 2.63%, #c2ca9b 96.05%);
}

.landing-page-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.upper-container {
  padding-top: 1rem;

  display: flex;
  flex-direction: row;
  justify-content: space-between;

  min-height: 40%;
  width: 100%;
}

.bubbles-container {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  width: 100%;
  height: 60%;
}

.landing-page-container .column {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  min-width: 4rem;
  overflow-y: scroll;
}

.landing-page-container .buttons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-end;
  padding: 0 2rem;
}

.back-to-exhibition {
  padding-top: 3rem;
}

.regular-background {
  width: 80%;
  border-radius: 4%;
}

.intro-text {
  color: white;
  padding: 3rem;
}
