/* Modal classes */
.modal {
  width: 20rem;
  height: 20rem;

  /* TODO: Would be nice to have the bubble be a percentage of the screen, like this*/
  /*width: calc(100vw - 15%);*/
  /*height: calc(100vw - 15%);*/

  border-radius: 20%;

  color: white;
  font-size: 0.75rem;
}

.regular-background {
  background: linear-gradient(
    180deg,
    rgb(187, 140, 215) 0%,
    rgb(226, 181, 114) 100%
  );
}

.green-background {
  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #6b9259 0%,
    rgba(142, 158, 108, 0.7) 100%
  );
  transform: rotate(-11.5deg);
}

.purple-background {
  background: linear-gradient(180deg, #8b6ab5 0%, #e4c4af 100%);
  transform: rotate(-8deg);
}

.overlay {
  display: flex;
  justify-content: center;
  align-items: center;

  /* Original styles*/
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.25);
}

.bubble {
  /*animation: bubbleBounce 3s ease 0s normal forwards;*/
  height: 3.75rem;
  width: 4rem;
  padding: 0.8rem;
}

.bubble.smaller-padding {
  padding: 0.4rem;
}

.bubble-container {
  height: 100%;
  width: 100%;

  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-template-rows: 0.5fr 2fr 1fr;
  gap: 0px 0px;
  grid-auto-flow: row;
  grid-template-areas:
    "meta user-id-and-audio"
    "meta description"
    "meta collect-and-share";
}

/*TODO: bubble-container:focus-visible becomes the dark green of the container*/
/*.bubble-container:focus-visible {*/
/*  border: #6b9259;*/
/*}*/

.bubble-container .description {
  grid-area: description;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 1rem;
}

.bubble-container .user-id-and-audio {
  grid-area: user-id-and-audio;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 3rem;
}

.bubble-container .collect-and-share {
  grid-area: collect-and-share;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.bubble-container .meta {
  grid-area: meta;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.bubble-container .meta span {
  transform: rotate(-90deg);
}

@keyframes bubbleBounce {
  0% {
    animation-timing-function: ease-in;
    opacity: 1;
    transform: translateY(-45px);
  }

  24% {
    opacity: 1;
  }

  40% {
    animation-timing-function: ease-in;
    transform: translateY(-24px);
  }

  65% {
    animation-timing-function: ease-in;
    transform: translateY(-12px);
  }

  82% {
    animation-timing-function: ease-in;
    transform: translateY(-6px);
  }

  93% {
    animation-timing-function: ease-in;
    transform: translateY(-4px);
  }

  25%,
  55%,
  75%,
  87% {
    animation-timing-function: ease-out;
    transform: translateY(0px);
  }

  100% {
    animation-timing-function: ease-out;
    opacity: 1;
    transform: translateY(0px);
  }
}
