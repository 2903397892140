.record_button-record {
  height: 4.5rem;
  width: 4.5rem;

  display: flex;
  align-items: center;
  justify-content: center;

  color: white;
  font-size: 0.625rem;

  align-self: start;

  background: #d24c22;
  border-radius: 100%;
  border: 3px solid #d71616;
  box-sizing: border-box;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.record_button-record .stop {
  height: 25%;
  width: 25%;
  background-color: white;
}

.record-container {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  width: 80%;
  min-height: 30%;
}
