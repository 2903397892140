.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  margin: 2rem;
}

.form > label {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}

.form .submit {
  margin-top: 3rem;
}

.form .success-message {
  padding: 2rem;
  background-color: lightgreen;
}
