.question-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;

  background: linear-gradient(180deg, #c4b6e0 0%, #eee1ce 100%);
}

.input {
  max-height: 24px;
  width: 100%;
}

.question_beginning {
  max-width: 60%;
}

.question_0.set {
  transform: rotate(-15deg);
}

.question_1 {
  height: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.question_1 .input_range {
  font-size: 0.75rem;
}

.question_1 span {
  display: inline;
}

.question_1-datalist-helper {
  display: flex;
  justify-content: space-between;
}

.question_3 {
  padding: 1rem;
}

/**
  This marks the beginning of styles for the element input of type range
*/

:root {
  --track-color: #847988;
  --thumb-color: #652f78;
}

/* Styles for the thumb*/

/* WebKit/Blink */
input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  border: 1px solid var(--thumb-color);
  height: 30px;
  width: 14px;
  border-radius: 0;
  background: var(--thumb-color);
  cursor: pointer;
  margin-top: -14px; /* You need to specify a margin in Chrome, but in Firefox and IE it is automatic */
}

/* Firefox */
input[type="range"]::-moz-range-thumb {
  border: 1px solid var(--thumb-color);
  height: 30px;
  width: 14px;
  border-radius: 0;
  background: var(--thumb-color);
  cursor: pointer;
}

/* Styles for the track*/

/* WebKit/Blink */
input[type="range"]::-webkit-slider-runnable-track {
  width: 100%;
  height: 24px;
  cursor: pointer;
  background: var(--track-color);
}

input[type="range"]:focus::-webkit-slider-runnable-track {
  background: var(--track-color);
}

/* Firefox */
input[type="range"]::-moz-range-track {
  width: 100%;
  height: 24px;
  cursor: pointer;
  background: var(--track-color);
}
