.created-container {
  height: 100vh;
  background: linear-gradient(
    180deg,
    rgba(151, 105, 36, 0.5) 0%,
    rgba(170, 101, 91, 0.5) 100%
  );

  display: flex;
  justify-content: center;
  align-items: center;
}
