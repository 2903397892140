.profile-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 0.5fr 0.5fr 3fr;
  gap: 0px 0px;
  grid-auto-flow: row;

  align-items: flex-end;
}

.profile-container .created-seeds {
  grid-area: 3 / 1 / 4 / 2;

  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  padding-bottom: 2rem;
  height: 80vh;
}

.profile-container .collected-seeds {
  grid-area: 3 / 2 / 4 / 3;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;

  padding-bottom: 2rem;
  height: 80vh;
}

.profile-container .bubbles {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.profile-container .links {
  grid-area: 2 / 1 / 3 / 3;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 0 1rem;

  font-size: 0.75rem;
  color: black;
}

.profile-container .id {
  grid-area: 1 / 1 / 2 / 3;
  color: white;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.profile-container .button {
  background: linear-gradient(270deg, #c3ca9c 6.76%, #e8bec9 93.24%);
}

.profile-container .label {
  color: white;
}
