.register-container {
  height: 100vh;
  background-position-y: 20% !important;
  color: white;

  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.register-container > * > * {
  display: block;
}

.register-container .heading {
  margin-bottom: 1rem;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.register-container form {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.register-container label {
  margin-top: 1rem;
  margin-bottom: 0.2rem;
}

.register-container input {
  margin-top: 0.5rem;
  background: transparent;
  border-top: none;
  border-left: none;
  border-right: none;
}

.register-container .hint {
  font-size: 0.75rem;
  margin-bottom: 0.2rem;
}

.register-container .submit {
  border-bottom: none;

  background: linear-gradient(180deg, #c9df98 0%, #c7aecd 100%);
  color: white !important;
}

.post-register-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  color: white;
  text-align: center;

  background: linear-gradient(
    180deg,
    rgba(223, 186, 213, 0.95) 0%,
    rgba(176, 187, 152, 0.95) 100%
  );
}

.post-register-container > * {
  margin-bottom: 1rem;
}

.post-register-container > div {
  margin-bottom: 2rem;
}

.post-register-container a {
  background: linear-gradient(180deg, #c9df98 0%, #c7aecd 100%);
}

.blue_flower {
  position: fixed;
  bottom: 1rem;
  width: 30%;
}
