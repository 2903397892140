.story-container {
  height: 100vh;
  overflow: hidden;

  background: none;
  color: white;
  font-size: 1.125rem;
  line-height: 2;
  text-align: center;
}

.story-container > *:not(button) {
  height: 100vh;
  padding: 2rem 3rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}

.story_page_1 {
  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #486327 0%,
    rgba(85, 104, 45, 0.7) 100%
  );
}

.story_page_1 p {
  position: absolute;
  top: calc(50% - 4rem);
}

.story_page_2 {
  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #486327 0%,
    rgba(85, 104, 45, 0.7) 100%
  );

  justify-content: center !important;
}

.story_page_2 #path {
  position: absolute;
  top: -17rem;
  left: -10rem;
}

.story_page_2 #spider {
  position: absolute;
  bottom: 0;
}

.story_page_3 {
  justify-content: center !important;

  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #486327 0%,
    rgba(85, 104, 45, 0.7) 100%
  );
}

.story_page_4 {
  justify-content: center !important;

  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #a2a444 0%,
    #758656 100%
  );
}

.story_page_5 {
  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #a2a444 0%,
    #758656 100%
  );
  color: white;
  font-size: 2.375rem;
}

.story_page_6 {
  background: radial-gradient(
    50.01% 50.01% at 82.11% 49.99%,
    #a2a444 0%,
    #758656 100%
  );
  color: white;
  font-size: 0.875rem;
  padding: 2rem 5rem;
}

.story_page_7 img {
  height: 100vh;
  width: 100vw;
}

.story_page_9 {
  color: black;
  background-size: 100vw 100vh;
  background-repeat: no-repeat;

  justify-content: center !important;
}

.back_button {
  position: absolute;
  top: 90vh;
  left: 10vw;
  z-index: 10;
  background-color: transparent;
  border: none;
}

.back_button svg {
  pointer-events: none;
}
